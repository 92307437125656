<template>
  <div class="header">
    <div class="content">
      <van-image width="100%" :src="require('@/assets/img/disclaimer.png')" class="img"/>
      <div class="text">
        <p class="text-notice">免责声明：</p>
        <p>建强村提供的买药服务为无偿服务，服务人员按照服务对象提供的医嘱或处方提供买药服务，因食用药物引起不适等，村委会及服务人员均不负责。</p>
        <div class="blank"></div>
        <p>建强村提供的辅具租赁服务为低偿服务，服务对象因辅具使用不当导致身体受损的，村委会及服务人员均不负责。</p>
      </div>
    </div>

  </div>
</template>

<script>
import topBar from '@/components/topBar/topBar'
export default {
  name: "disclaimer",
  components: {
    topBar
  }
}
</script>

<style scoped>
.header {
  height: 100%;
  background-color: #F5F5F5
}
.header .top-bar {
  font-weight: bold;
}
.content {
  background: white;
  height: 100%
}
.img {
  padding: 4%
}
.text {
  margin: 0 5%;
  font-size: 24px;
  line-height: 45px;
  color: #686868;
}
.text-notice {
  font-weight: bold;
}
.blank {
  height: 60px;
  color: #686868
}

</style>
